import React from 'react';
import '../assets/scss/detailSuccessStory.scss';
import { Link } from 'gatsby';
const StoryCard = ({
  companyImg,
  companyName,
  companyText,
  techStack,
  bgColor,
  slug,
}: {
  companyImg: string;
  companyName: string;
  companyText: string;
  techStack: string[];
  bgColor: string;
  slug: string;
}) => {
  return (
    <div className='story-card  d-flex flex-column justify-content-between pb-3 bg-white '>
      <div
        className='d-flex justify-content-center story-img-container'
        style={{ backgroundColor: bgColor }}
      >
        <img src={companyImg} className='company-img-container' alt='company-img' />
      </div>

      <div className='px-3'>
        <div className='mb-2 fw-700 fnt-24 fnt-sm-18 color-black'>{companyName}</div>
        <div className='mb-1 fw-400 fnt-18 color-black fnt-sm-14 story-para-height'>
          {companyText}
        </div>
        <div className='mb-3 read-more '>
          <Link to={`/success-stories/${slug}`} className='fnt-18 fnt-sm-14 fw-500 text-black '>
            Read more
          </Link>
        </div>
      </div>

      <div className='px-3'>
        <div className='mb-2 fw-700 fnt-18 fnt-sm-14 color-black'>Technologies used</div>
        {techStack?.map((tech: any) => <img src={tech.img} className='me-2' alt='tech-img' />)}
      </div>
    </div>
  );
};

export default StoryCard;
